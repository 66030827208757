.nivo-lightbox-overlay {
  z-index: 99998;
  visibility: hidden;
  opacity: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.nivo-lightbox-overlay.nivo-lightbox-open {
  visibility: visible;
  opacity: 1;
}

.nivo-lightbox-wrap {
  position: absolute;
  inset: 10%;
}

.nivo-lightbox-content {
  width: 100%;
  height: 100%;
}

.nivo-lightbox-title-wrap {
  z-index: 99999;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nivo-lightbox-nav {
  display: none;
}

.nivo-lightbox-prev {
  position: absolute;
  top: 50%;
  left: 0;
}

.nivo-lightbox-next {
  position: absolute;
  top: 50%;
  right: 0;
}

.nivo-lightbox-close {
  position: absolute;
  top: 2%;
  right: 2%;
}

.nivo-lightbox-image {
  text-align: center;
}

.nivo-lightbox-image img {
  vertical-align: middle;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.nivo-lightbox-content iframe {
  width: 100%;
  height: 100%;
}

.nivo-lightbox-inline, .nivo-lightbox-ajax {
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
}

.nivo-lightbox-error {
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 1px #000;
  width: 100%;
  height: 100%;
  display: table;
}

.nivo-lightbox-error p {
  vertical-align: middle;
  display: table-cell;
}

.nivo-lightbox-notouch .nivo-lightbox-effect-fade, .nivo-lightbox-notouch .nivo-lightbox-effect-fadeScale, .nivo-lightbox-notouch .nivo-lightbox-effect-slideLeft, .nivo-lightbox-notouch .nivo-lightbox-effect-slideRight, .nivo-lightbox-notouch .nivo-lightbox-effect-slideUp, .nivo-lightbox-notouch .nivo-lightbox-effect-slideDown, .nivo-lightbox-notouch .nivo-lightbox-effect-fall {
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.nivo-lightbox-effect-fadeScale .nivo-lightbox-wrap {
  -o-transition: all .3s;
  transition: all .3s;
  transform: scale(.7);
}

.nivo-lightbox-effect-fadeScale.nivo-lightbox-open .nivo-lightbox-wrap {
  transform: scale(1);
}

.nivo-lightbox-effect-slideLeft .nivo-lightbox-wrap, .nivo-lightbox-effect-slideRight .nivo-lightbox-wrap, .nivo-lightbox-effect-slideUp .nivo-lightbox-wrap, .nivo-lightbox-effect-slideDown .nivo-lightbox-wrap {
  -o-transition: all .3s cubic-bezier(.25, .5, .5, .9);
  transition: all .3s cubic-bezier(.25, .5, .5, .9);
}

.nivo-lightbox-effect-slideLeft .nivo-lightbox-wrap {
  transform: translateX(-10%);
}

.nivo-lightbox-effect-slideRight .nivo-lightbox-wrap {
  transform: translateX(10%);
}

.nivo-lightbox-effect-slideLeft.nivo-lightbox-open .nivo-lightbox-wrap, .nivo-lightbox-effect-slideRight.nivo-lightbox-open .nivo-lightbox-wrap {
  transform: translateX(0);
}

.nivo-lightbox-effect-slideDown .nivo-lightbox-wrap {
  transform: translateY(-10%);
}

.nivo-lightbox-effect-slideUp .nivo-lightbox-wrap {
  transform: translateY(10%);
}

.nivo-lightbox-effect-slideUp.nivo-lightbox-open .nivo-lightbox-wrap, .nivo-lightbox-effect-slideDown.nivo-lightbox-open .nivo-lightbox-wrap {
  transform: translateY(0);
}

.nivo-lightbox-body-effect-fall .nivo-lightbox-effect-fall {
  perspective: 1000px;
}

.nivo-lightbox-effect-fall .nivo-lightbox-wrap {
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  transform: translateZ(300px);
}

.nivo-lightbox-effect-fall.nivo-lightbox-open .nivo-lightbox-wrap {
  transform: translateZ(0);
}
/*# sourceMappingURL=index.0edff140.css.map */
